import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, Card, CardContent, Grid, TextField, Typography } from "@mui/material";
import { Theme } from "app/shared/utils";
import { useEffect, useMemo, useState } from "react";
import { BsBoxArrowUp, BsChevronDown, BsPlusLg } from "react-icons/bs";
import { TabelaEscolas } from "./components/TabelaEscolas";
import { ModalCadastro } from "./components/ModalCadastro";
import { useService } from "app/shared/hooks";
import { GenericModal, HeaderAnoGlobal, LoaderComponent } from "app/shared/components";
import { buscaEscolas } from "app/services/escola";
import { ModalEdicao } from "./components/ModalEdicao";
import { exibeValor, formataValorReais, reaisParaNumero } from "app/shared/utils/helpers";
import { Filtro } from "./components/Filtro";
import { ModalExportacao } from "./components/ModalExportacao";
import { excluiMacroAcaoEscola } from "app/services/macro-acoes-escola/delete";
import { salvaMacroAcaoEscola } from "app/services/macro-acoes-escola/post"
import toastr from 'toastr'
import { LoadingButton } from "@mui/lab";
import { buscaMacroAcoesEscola } from "app/services/macro-acoes-escola";
import { useSelector } from "react-redux";

export function MacroAcao(){
  const [openModalCadastro, setOpenModalCadastro] = useState(false)
  const [openModalEdicao, setOpenModalEdicao] = useState(false)
  const [openModalExportacao, setOpenModalExportacao] = useState(false)
  const [openModalExclusao, setOpenModalExclusao] = useState(false)
  const [dadosModal, setDadosModal] = useState({})
  const [macroAcaoEscolas, setMacroAcaoEscolas] = useState([])
  const [escolasSelecionadas, setEscolasSelecionadas] = useState([])
  const [valor, setValor] = useState('')
  const [escolas, setEscolas] = useState([])
  const [inputPesquisa, setInputPesquisa] = useState('')
  const [dadosFiltro, setDadosFiltro] = useState({macroAcao: null, tipoPlano: null, plano: ''})
  const [insertMacro, setInsertMacro] = useState('')
  const [existeMacro, setExisteMacro] = useState({macro: '', existe: true, msgError: 0})
  const ano = useSelector((state) => state.global.ano)

  const { request: buscaMacroAcoesEscolaReq, response: resMacroEscola, retry: retryMacroEscola }  = useService(buscaMacroAcoesEscola, false, 
    (result) => { // onSuccess
      const dados = []   
      result.data.forEach(r => { // Verifica os dados antes de inserir na lista, para não inserir repetidos
        if(!dados.find(macro => 
          macro.macroAcao_id ===  r.macro_acao_id
          && macro.parcela_id === r.parcela_id
          && macro.escola_id === r.escola_id
        )){
          dados.push({        
            id: r.id,
            macroAcao: r.macro_acao.nome,
            macroAcao_id: r.macro_acao_id,
            tipoPlano_id: r.plano_id,
            parcela_id: r.parcela_id,
            escola: r.escola.nome,
            escola_id: r.escola_id,        
            valor: exibeValor(r.valor_total)
          })
        }  
    })
    setMacroAcaoEscolas(dados)
  })

  const { request: buscaEscolasReq, response: resEscola, retry: retryBuscaEscolas } = useService(buscaEscolas, true, 
    (result) => { setEscolas(result) },
  )

  const { request: SalvaMacroAcaoEscola, response: resPostMacroEscola } = useService(salvaMacroAcaoEscola, false, 
    (result) => {  // onSucess
      toastr.success('Valor registrado com sucesso!') 
      setMacroAcaoEscolas([...macroAcaoEscolas, ...result.map((r, i) => ({
        id: r.id,
        macroAcao: dadosFiltro.macroAcao.label,
        macroAcao_id: dadosFiltro.macroAcao.value,
        tipoPlano_id: r.plano_id,
        parcela_id: dadosFiltro.parcela,
        escola: escolasSelecionadas[i].label,
        escola_id: escolasSelecionadas[i].value,        
        valor: exibeValor(r.valor_total)
      }))])

      setEscolasSelecionadas([])
      setValor('')
    }, 
    () => { toastr.error('Ocorreu um problema ao salvar o valor')}, // onError
  )

  const { request: excluiMacroAcaoEscolaReq , response: { loading: excluiMacroLoading } } = useService(excluiMacroAcaoEscola, false, 
    () => {
      setMacroAcaoEscolas(macroAcaoEscolas.filter(item => item.id !== dadosModal.id ))
      setOpenModalExclusao(false)
      toastr.success('Registro apagado com sucesso!')
    }, 
    () => { toastr.error('Ocorreu um problema ao excluir este registro')}
  )

  const limit = 5000 // Usado para obter todos os dados de uma vez, e não apenas dados paginados

  const handleSubmit = () => {
    SalvaMacroAcaoEscola({
      macro_acao_id: dadosFiltro.macroAcao.value,
      parcela_id: dadosFiltro.parcela,
      valor_total: reaisParaNumero(valor),
      escola_ids: escolasSelecionadas.map(escola => escola.value),
    })
  }

  const podeSalvar = useMemo(() => {
    if(dadosFiltro.macroAcao && dadosFiltro.tipoPlano && dadosFiltro.parcela && escolasSelecionadas.length && valor){
      return true
    }
    return false
  }, [dadosFiltro.macroAcao, dadosFiltro.tipoPlano, dadosFiltro.parcela, escolasSelecionadas, valor])

  // "macroAcaoEscolas" armazena todos os dados, e "dadosTabela" somente os dados filtrados
  const dadosTabela = useMemo(() => { 
    return  macroAcaoEscolas.filter(m => 
      m.macroAcao_id === dadosFiltro.macroAcao?.value
      && m.parcela_id === dadosFiltro.parcela
      && (m.escola.toUpperCase().includes(inputPesquisa.toUpperCase())
        || m.valor.includes(inputPesquisa))
    )
  }, [macroAcaoEscolas, dadosFiltro.macroAcao, dadosFiltro.parcela, inputPesquisa])

  const optionsEscolas = useMemo(() => {
     // Se a escola não está na tabela, então exibe nas opções do filtro
    return escolas.filter(escola => !Boolean(dadosTabela.find(dado => dado.escola_id === escola.id)))
      .map(escola => { return { label: escola.nome, value: escola.id } }) 
  }, [dadosTabela, escolas])

  useEffect(() => {
    buscaEscolasReq({limit})
  }, [])

  useEffect(() => {
    buscaMacroAcoesEscolaReq({limit, with: "escola,macro_acao", 'parcela[ano]': ano})  
  }, [ano])

  return <>
    <HeaderAnoGlobal
      titulo="Cadastro de Macro Ação"
      colorTitulo={Theme.colors.blue.dark}
    />

    <Card sx={{ width: '100%', marginTop: 2 }} elevation={2}>
      <CardContent>
        <Box display='flex' justifyContent='flex-end' marginBottom={2}>
          <Button variant="contained" startIcon={<BsPlusLg/>} size="small" onClick={() => setOpenModalCadastro(true)} >Nova macro ação</Button>
        </Box>
        <Filtro set={setDadosFiltro} insertMacro={insertMacro} existeMacro={{get: existeMacro, set: setExisteMacro}} />
      </CardContent>
    </Card>

    <Accordion sx={{ marginTop: 2 }}>
      <AccordionSummary expandIcon={<BsChevronDown />}>
        <Typography color={Theme.colors.blue.dark} fontWeight='bold' > Adicionar escola </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid item xs={12} md={8}>
            <LoaderComponent {...resEscola} message='Buscando escolas' retry={retryBuscaEscolas} errorMessage="Falha ao buscar escolas">
              <Autocomplete         
                options={optionsEscolas}
                isOptionEqualToValue={(option, value) => option.value === value?.value}
                getOptionLabel={(option) => option.label}
                value={escolasSelecionadas}
                renderInput={(params) => <TextField {...params} label="Escola" />}
                fullWidth
                size="small"
                disabled={resEscola.loading || Boolean(!dadosFiltro.macroAcao || !dadosFiltro.tipoPlano || !dadosFiltro.parcela)}
                onChange={ (e, value) => setEscolasSelecionadas(value) }
                multiple
                noOptionsText="Nenhuma escola encontrada"
              />
            </LoaderComponent>
          </Grid>

          <Grid item xs sx={{ marginLeft: {xs: 0, md: 1}, marginTop: {xs: 2, md: 0} }}>
            <TextField
              label="Valor (R$)"
              variant="outlined"
              fullWidth
              value={valor}
              onChange={e => setValor(formataValorReais(e))}
              size="small"
              disabled={resEscola.loading || Boolean(!escolasSelecionadas.length)}
            />
          </Grid>

          <Grid sx={{ marginLeft: 1, marginTop: {xs: 2, md: 0} }}>
            <LoadingButton
              variant="contained"  
              onClick={handleSubmit} 
              disabled={!podeSalvar} 
              loading={resPostMacroEscola.loading}
            >
              Adicionar
            </LoadingButton>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>

    <Card sx={{ marginTop: 2 }} elevation={2}>
      <CardContent>
        <Box display='flex' justifyContent='flex-end' mb={2}>            
          <Button variant="contained" startIcon={<BsBoxArrowUp/>} 
            onClick={() => {setOpenModalExportacao(true) }}
            disabled={!Boolean(dadosTabela.length)}  
            size="small"
          >
            Copiar/colar valores
          </Button>
        </Box>
   
        <TextField
          fullWidth
          size="small"
          label="Pesquise por escolas ou valores"
          disabled={dadosTabela.length === 0 && inputPesquisa === ''}
          onChange={e => setInputPesquisa(e.target.value)}
          sx={{mb: 2}}
        />
        <LoaderComponent {...resMacroEscola} message="Buscando valores" errorMessage="Falha ao buscar Valores" retry={retryMacroEscola}>
          <TabelaEscolas 
            filtrosSelecionados={ Boolean(dadosFiltro.macroAcao && dadosFiltro.tipoPlano && dadosFiltro.parcela) }
            dados={dadosTabela} 
            onEdit={ dados => {
              setDadosModal(dados)
              setOpenModalEdicao(true)
            }}        
            onDelete={ dados => {
              setDadosModal(dados)
              setOpenModalExclusao(true)
            }}         
          />
        </LoaderComponent>
      </CardContent>
    </Card>
   
    <ModalCadastro
      open={openModalCadastro} 
      close={ () => setOpenModalCadastro(false) }
      setInsertMacro={setInsertMacro}
      existeMacro={{get: existeMacro, set: setExisteMacro}}
    />

    <ModalEdicao
      dados={dadosModal}
      open={openModalEdicao}
      close={ () => setOpenModalEdicao(false) }
      escolas={escolas.filter(escola => !dadosTabela.find(dado => (dado.escola_id === escola.id)) || escola.id === dadosModal.escola_id)
        .map(escola => { return { label: escola.nome, value: escola.id } }) 
      }
      successAction={(id, valor, escola_id, escola) => {
        setMacroAcaoEscolas( macroAcaoEscolas.map(item => item.id === id ? {...item, valor, escola_id, escola} : item))        
      }}
    />

    <ModalExportacao
      open={openModalExportacao}
      close={ () => setOpenModalExportacao(false) }
      dados={dadosTabela}
      successAction={(dadosBase, result) => {
        const dadosExportar = []
        dadosBase.forEach((d, i) => { // Percorre os dados da tabela
          if(!macroAcaoEscolas.find(macro => // Verifica se os dados a serem exportados ja existem naquelas configurações
            macro.macroAcao_id ===  result[i].macro_acao_id
            && macro.parcela_id === result[i].parcela_id
            && macro.escola_id === d.escola_id
          )){
            dadosExportar.push({ //Só adiciona se não forem encontrados
              id: result[i].id,
              macroAcao: d.macroAcao,
              macroAcao_id: result[i].macro_acao_id,
              tipoPlano_id: result[i].plano_id,
              parcela_id: result[i].parcela_id,
              escola: d.escola,
              escola_id: d.escola_id,        
              valor: d.valor
            })
          }
        })
        setMacroAcaoEscolas([...macroAcaoEscolas, ...dadosExportar])    
      }}
    />

    <GenericModal
      title="Confirmação de exclusão"
      open={openModalExclusao}
      onClose={ () => setOpenModalExclusao(false)}
      size="sm"
      isLoading={excluiMacroLoading}
      isDisabledSave={excluiMacroLoading}
      textButton="EXCLUIR"
      colorButton="error"
      onSave={() => excluiMacroAcaoEscolaReq({id: dadosModal.id}) }     
    >
      <Box marginTop={2}>
        <Typography color="error.main">Tem certeza que deseja excluir esse item? esta ação não pode ser desfeita.</Typography>
        <Typography>Macro Ação: {dadosModal.macroAcao}</Typography>
        <Typography>Escola: {dadosModal.escola}</Typography>
        <Typography>Valor: {dadosModal.valor}</Typography>
      </Box>
    </GenericModal>
  </>
}