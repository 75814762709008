
export const saveAllMacroAcao = (api, props, controller) => {
  return api.post( "macro_acoes_escola/save_all", props, {
    signal: controller.signal,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const salvaMacroAcaoEscola = (api, props, controller) => {
  return api.post("macro_acoes_escola/save_all_escolas", props, {
    signal: controller.signal
  });
};

export const exportaMacroAcaoEscola = (api, props, controller) => {
  return api.post("macro_acoes_escola/exportar", props, {
    signal: controller.signal
  });
};
