import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { editaMacroAcaoEscola } from 'app/services/macro-acoes-escola/put';
import { GenericModal } from 'app/shared/components';
import { useService } from 'app/shared/hooks';
import { formataValorReais, reaisParaNumero } from 'app/shared/utils/helpers';
import { useEffect, useRef, useState } from 'react';
import toastr from 'toastr'

export function ModalEdicao({ open, close, successAction, dados, escolas}) {
  const [valor, setValor] = useState(dados.valor)
  const [escola, setEscola] = useState(dados.escola_id)
  const inputValorRef = useRef(null)

  const onSucess = () => {
    successAction(dados.id, valor, escola, escolas.find(e => e.value === escola).label)
    close()
    toastr.success('Macro Ação cadastrada com sucesso!')
  }

  const onError = () => {
    toastr.error('Ocorreu um problema, tente novamente mais tarde')
  }

  const { request , response: { loading } } = useService(editaMacroAcaoEscola, false , onSucess, onError)

  function handleSubmit(){
    request({
      id: dados.id, 
      macro_acao_id: dados.macroAcao_id,
      parcela_id: dados.parcela_id,
      escola_id: escola,
      valor_total: reaisParaNumero(valor)
    })
  }

  useEffect(() => { 
    setEscola(dados.escola_id)
    setValor(dados.valor)

    let timeout = null
    if(open){
      setValor(dados.valor)
      timeout = setTimeout(() => inputValorRef.current?.focus(), 10) // Foca o input assim que a modal abre
    }
    return () => clearTimeout(timeout)
  }, [open])

  return (
    <div>
      <GenericModal
        open={open}
        onClose={close}
        title="Edição de valor"
        isLoading={loading}
        isDisabledSave={Boolean(!valor) || loading}
        onSave={handleSubmit}
      >
        <TextField
          sx={{ marginTop:  2}}
          label="Macro Ação"
          variant="outlined"
          fullWidth
          value={dados.macroAcao}
          size="small"
          disabled
        />

        <FormControl fullWidth variant="outlined" size="small" sx={{ marginTop:  2}}>
          <InputLabel id="custom-select-label">Escola</InputLabel>
          <Select
            labelId="custom-select-label"
            id="custom-select"
            label="Escola"
            value={escolas?.find?.(e => e.value === escola)?.value || ''} // So pra evitar o warning de value nao encontrado
            onChange={(e) => setEscola(e.target.value)}        
          >
            {escolas.map((e) => (
              <MenuItem key={e.value} value={e.value}>
                {e.label}
              </MenuItem>
            ))}
            {!escolas?.length && <MenuItem disabled>Nenhuma disponível</MenuItem>}
          </Select>
        </FormControl>

        <TextField
          inputRef={inputValorRef}
          sx={{ marginTop:  2}}
          label="Valor (R$)"
          variant="outlined"
          fullWidth
          value={valor || ''}
          onChange={e => setValor(formataValorReais(e))}
          size="small"
        />

      </GenericModal>
    </div>
  );
}  
