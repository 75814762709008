import React, { useEffect } from "react";
import { useService } from "app/shared/hooks";
import { Button } from "@mui/material";

const PdfButton = ({
    requestFunction,
    params = {},
    isMobiReq = false,
    fileName,
    buttonLabel = "Gerar PDF",
    buttonStyle = {},
    variant = "contained",
    ...props
}) => {

    const {
        request: getPdfReq,
        response,
    } = useService(
        requestFunction,
        isMobiReq,
        () => { },
        () => { },
        { responseType: "blob" }
    );

    const { data, loading } = response || {};
    const time = new Date().getTime()

    const handlePdfGeneration = () => {
        const blob = new Blob([data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = fileURL;
        link.download = `${fileName}-${time}.pdf`;
        link.click();
        URL.revokeObjectURL(fileURL);
    }

    const gerarPDF = () => {
        getPdfReq(params);
    };

    useEffect(() => {
        if (data) {
            handlePdfGeneration();
        }
    }, [data]);

    return (
        <Button
            variant={variant}
            onClick={gerarPDF}
            disabled={loading}
            sx={{ ...buttonStyle }}
            size="small"
            {...props}
        >
            {loading ? "Carregando..." : buttonLabel}
        </Button>
    );
};

export default PdfButton;
