import { Outlet, useLocation } from "react-router";
import { LayoutContainer } from "./styles";
import {
  Collapse,
  Container,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useEffect, useState } from "react";
import NavBarMobiClass from "@renatomobi/js-navbar-mobi/NavBarMobi";
import navMobi from "@renatomobi/js-navbar-mobi";
import BreadCrumb from "./BreadCrumb";
import { LayoutFooter } from "app/shared/components";
import { getUser, logout } from "app/services/auth/service";
import { Link } from "react-router-dom";
import { FaChevronDown, FaChevronUp, FaMonero, FaSignOutAlt } from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";
import { BsDot } from "react-icons/bs";
import packageInfo from "../../../../package.json";
import { AppKey, ROLES, RoutesPath } from "app/shared/constants";
import "./main.css";
import { useDispatch } from "react-redux";
import { setUser } from "app/redux/reducers/globalSlice";
import { useApp } from "app/shared/hooks";
import { FiBarChart2, FiList, FiSlash } from "react-icons/fi";

const VERSAO_WEB = packageInfo.version;

const MainLayout = () => {
  const dispatch = useDispatch();
  const { user } = useApp();
  const [openSubItens, setOpenSubItens] = useState({});
  const [showMenu, setShowMenu] = useState(false);
  const currentRole = user?.perfil || 0;
  const location = useLocation();

  const preparaNavBar = () => {
    let options = new NavBarMobiClass();
    options.setAppkey(AppKey);
    options.setUrlLogo("/img/logo.svg");
    options.setCorBackground("#1976d2");
    options.setCorTexto("#ffffff");
    options.setCorIcons("#ffffff");
    options.setNomeApp("PAAR");
    return options;
  };

  useEffect(() => {
    let options = preparaNavBar();

    options.setOnReady(() => {
      options.to_perfil = () => {
        //console.log('perfil')
      };
      options.setOnToogleMenu(() => {
        setShowMenu(!showMenu);
      });
      options.setOnShowMenu(() => {
        setShowMenu(true);
      });
      options.setOnHideMenu(() => {
        setShowMenu(false);
      });
    });

    navMobi(options);
    getUser().then((u) => {
      dispatch(setUser(u));
    });
  }, []);

  const navItems = [
    {
      primary: "Dashboard",
      path: RoutesPath.DASHBOARD.path,
      perfil: [ROLES.PERFIL_ESCOLA, ROLES.PERFIL_GESTOR, ROLES.PERFIL_SUP],
      icon: <FiBarChart2 size={20} />,
    },
    {
      primary: "Recursos",
      path: RoutesPath.RECURSO.path,
      perfil: [ROLES.PERFIL_ESCOLA, ROLES.PERFIL_GESTOR, ROLES.PERFIL_SUP],
      icon: <FiList size={20} />,
    },
    {
      primary: "Bloqueio/Liberação",
      path: RoutesPath.RECURSO_BLOQUEIO.path,
      perfil: [ROLES.PERFIL_GESTOR],
      icon: <FiSlash size={20} />,
    },
    {
      primary: 'Macro Ação',
      path: RoutesPath.MACRO_ACAO.path,
      perfil: [ROLES.PERFIL_GESTOR],
      icon: <FaMonero size={20} />
    },
    {
      primary: 'Sair',
      perfil: [ROLES.PERFIL_ESCOLA, ROLES.PERFIL_GESTOR, ROLES.PERFIL_SUP],
      icon: <FaSignOutAlt size={20} />
    }
  ];

  const showSubItens = (campo) => {
    setOpenSubItens({ ...openSubItens, [campo]: !openSubItens[campo] });
  };

  return (
    <>
      {/*<ToastContainer />*/}
      <LayoutContainer>
        <div id="navbar-mobi"></div>
        <Drawer
          style={{ zIndex: 99999 }}
          open={showMenu}
          onClose={() => setShowMenu(false)}
        >
          <div className="menu-lateral-calendario">
            <div className="drawer-header">
              <span>Menu</span>
              <IconButton
                onClick={() => setShowMenu(false)}
                style={{ width: 40, height: 40 }}
              >
                <MdOutlineClose />
              </IconButton>
            </div>
            <div>
              {user &&
                navItems
                  .filter((i) => i.perfil.includes(parseInt(currentRole)))
                  .map((item, index) => (
                    <List component="div" key={index} disablePadding>
                      {!item.link ? (
                        <Link
                          to={
                            item.path === ""
                              ? location.pathname + location.search
                              : item.path
                          }
                          key={index}
                          className="item-navegacao"
                          onClick={() => {
                            !item.subItens
                              ? setShowMenu(false)
                              : showSubItens(item.primary)
                            if(item.primary === 'Sair'){ logout() }
                          }}
                        >
                          <ListItemButton
                            selected={location.pathname === item.path}
                          >
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.primary} />
                            {item.subItens ? (
                              openSubItens[item.primary] ? (
                                <FaChevronUp size={14} color={"#757575"} />
                              ) : (
                                <FaChevronDown color="#757575" size={14} />
                              )
                            ) : null}
                          </ListItemButton>
                        </Link>
                      ) : (
                        <a
                          href={item.path}
                          style={{ textDecoration: "none" }}
                          target="_blank"
                          rel="noreferrer"
                          key={index}
                          className="item-navegacao"
                          onClick={() => setShowMenu(false)}
                        >
                          <ListItemButton>
                            <ListItemIcon
                              sx={{ maxWidth: "19px", height: "19px" }}
                            >
                              {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.primary} />
                          </ListItemButton>
                        </a>
                      )}
                      {item.subItens && (
                        <Collapse
                          in={openSubItens[item.primary]}
                          timeout="auto"
                          unmountOnExit
                        >
                          {user &&
                            item.subItens
                              .filter((i) =>
                                i.perfil.includes(parseInt(user.perfil))
                              )
                              .map((subItem, i) => (
                                <Link
                                  to={subItem.path}
                                  key={i}
                                  className="item-navegacao"
                                  onClick={() => setShowMenu(false)}
                                >
                                  <ListItemButton
                                    selected={
                                      location.pathname === subItem.path
                                    }
                                    sx={{ pl: 5 }}
                                  >
                                    <ListItemIcon sx={{ minWidth: "30px" }}>
                                      <BsDot />
                                    </ListItemIcon>
                                    <ListItemText primary={subItem.primary} />
                                  </ListItemButton>
                                </Link>
                              ))}
                        </Collapse>
                      )}
                    </List>
                  ))}
              <Grid
                sx={{
                  position: "absolute",
                  left: "35%",
                  bottom: 10,
                }}
              >
                Versão {VERSAO_WEB}
              </Grid>
            </div>
          </div>
        </Drawer>
        <Container
          sx={{
            mt: "20px",
          }}
        >
          <BreadCrumb />
          <Outlet />
        </Container>
      </LayoutContainer>
      <LayoutFooter />
    </>
  );
};

export default MainLayout;
