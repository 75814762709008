import { Alert, Button, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { StyledTableContainer } from "app/shared/components";
import { BsPencil, BsTrash } from "react-icons/bs";

export function TabelaEscolas({filtrosSelecionados, dados, onEdit, onDelete}){

  return <>
      <StyledTableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ESCOLA</TableCell>
              <TableCell>VALOR (R$)</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { dados.map((d) => (
              <TableRow key={d.id}>
                <TableCell>{d.escola} </TableCell>
                <TableCell>{d.valor}</TableCell>              
                <TableCell align="right">
                  { !d.tipoPlano_id && <> 
                    <Button variant="contained" onClick={() => onEdit(d)}  sx={{minWidth: 'auto', padding: '8px', marginRight: "5px"}} title="Editar" > <BsPencil/> </Button>                     
                    <Button variant="contained" color="error" onClick={() => onDelete(d)}  sx={{minWidth: 'auto', padding: '8px'}} title="Excluir" > <BsTrash/> </Button>
                  </> }
                </TableCell>
              </TableRow>
            ))}
            { !dados.length &&
              <TableRow>
                <TableCell colSpan={3}>
                  <Alert severity="info">  
                    { filtrosSelecionados ? 'Nenhuma escola escontrada' : 'Selecione os filtros para visualizar os dados' }
                  </Alert>
                </TableCell>
              </TableRow>
            }
          </TableBody>
      </Table>
    </StyledTableContainer>
  </>
}