import { Autocomplete, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import { buscaMacroAcoes } from "app/services/macro-acoes/get"
import { buscaParcelas } from "app/services/parcela"
import { buscaTipoPlanos } from "app/services/plano"
import { LoaderComponent } from "app/shared/components"
import { useService } from "app/shared/hooks"
import { removerDuplicados } from "app/shared/utils/helpers"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

export function Filtro({modal = false, set, insertMacro, existeMacro, ignoreCache = false}){
  const ano = useSelector((state) => state.global.ano)
  const [macroAcao, setMacroAcao] = useState(null)
  const [tipoPlano, setTipoPlano] = useState(null)
  const [parcela, setParcela] = useState('')

  const [macroAcoes, setMacroAcoes] = useState([])
  const [tipoPlanos, setTipoPlanos] = useState([])
  const [parcelas, setParcelas] = useState([])

  const { request: buscaMacroAcoesReq, response: resMacro, retry: retryBuscaMacroAcoes }  = useService(buscaMacroAcoes, false, 
    (result) => { setMacroAcoes(removerDuplicados(result.data, 'nome')) },
    () => {},
    { cache: true, cacheKey: 'buscaMacroAcoes' }
  )

  const { request: buscaTipoPlanosReq, response: resPlano, retry: retryBuscaTipoPlanos } = useService(buscaTipoPlanos, false, 
    (result) => { setTipoPlanos(result.data) },
    () => {},
    { cache: true, cacheKey: 'buscaTipoPlanos' }
  )

  const { request: buscaParcelasReq, response: resParcela, retry: retryBuscaParcelas } = useService(buscaParcelas, false, 
    (result) => { setParcelas(result.data) },
    () => {},
    { cache: true, cacheKey: 'buscaParcelas' }
  )

  const limit = 5000

  useEffect(() => { 
    set({macroAcao, tipoPlano, parcela})
  }, [macroAcao, tipoPlano, parcela])

  useEffect(() => {
    if(!insertMacro) return
    const macro = macroAcoes.find(m => m.nome.toUpperCase() === insertMacro.nome.toUpperCase())
    if(macro){ // Se encontrar, apenas seleciona, senão, adiciona na lista de macroAcoes e seleciona
      setMacroAcao({ label: macro.nome, value: macro.id })
    } else {
      setMacroAcoes([...macroAcoes, insertMacro])
      setMacroAcao({ label: insertMacro.nome, value: insertMacro.id })
    }

  }, [insertMacro])
  
  useEffect(() => {
    setParcela('')
    if(tipoPlano && ano){
      buscaParcelasReq({tipo_plano_id: tipoPlano.value, ano, limit: 5000}, ignoreCache)
    }
  }, [tipoPlano, ano])

  useEffect(() => {
    buscaMacroAcoesReq({limit}, ignoreCache)
    buscaTipoPlanosReq({limit}, ignoreCache)
  }, [])

  useEffect(() => { // Verifica se o texto do input ja existe nos nomes de macro ações
    if(existeMacro?.get?.macro){
      const existe = Boolean(macroAcoes.find(m => m.nome.toUpperCase() === existeMacro.get.macro.toUpperCase()))
      existeMacro.set({ // Aciona o useEffect do componente "ModalCadastro"
        ...existeMacro.get,
        existe,
        msgError: existe ? existeMacro.get.msgError + 1 : existeMacro.get.msgError
      })
    } 
  }, [existeMacro?.get?.macro])

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={modal ? 12 : 4}>
        <LoaderComponent {...resMacro} message='Buscando macro ações' retry={retryBuscaMacroAcoes} errorMessage="Falha ao buscar macro ações"> 
          <Autocomplete         
            options={ macroAcoes.map(macro => { return { label: macro.nome, value: macro.id } }) }
            isOptionEqualToValue={(option, value) => option.value === value?.value}
            getOptionLabel={(option) => option.label}
            value={macroAcao}
            renderInput={(params) => <TextField {...params} label="Macro Ação"/>}
            fullWidth
            size="small"
            disabled={resMacro.loading}
            onChange={ (e, value) => setMacroAcao(value) }   
            noOptionsText="Nenhuma macro ação encontrada"          
          />
        </LoaderComponent>
      </Grid>

      <Grid item xs={modal ? 12 : 6} md={modal ? 12 : 4} sx={{ marginTop: {xs: 1, md: modal ? 1 : 0} }}>
        <LoaderComponent {...resPlano} message='Buscando planos' retry={retryBuscaTipoPlanos} errorMessage="Falha ao buscar planos"> 
          <Autocomplete         
            options={ tipoPlanos.map(plano => { return { label: plano.descricao, value: plano.id } }) }
            isOptionEqualToValue={(option, value) => option.value === value?.value}
            getOptionLabel={(option) => option.label}
            value={tipoPlano}
            renderInput={(params) => <TextField {...params} label="Plano" />}
            fullWidth
            size="small"
            disabled={resPlano.loading || resParcela.loading}
            onChange={ (e, value) => setTipoPlano(value) }
            noOptionsText="Nenhum plano encontrado"
          />
        </LoaderComponent>
      </Grid>

      <Grid item xs={modal ? 12: 6} md={modal ? 12 : 4} sx={{ marginTop: {xs: 1, md: modal ? 1 : 0} }}>
        <LoaderComponent {...resParcela} message='Buscando parcelas' retry={retryBuscaParcelas} errorMessage="Falha ao buscar parcelas">
          <FormControl fullWidth variant="outlined" size="small">
            <InputLabel id="custom-select-label">Parcela</InputLabel>
            <Select
              labelId="custom-select-label"
              id="custom-select"
              label="Parcela"
              value={parcela}
              onChange={(e) => setParcela(e.target.value)}
              size="small"
              disabled={ Boolean(!tipoPlano) || resParcela.loading }
            >
              {parcelas.map((parcela) => (
                <MenuItem key={parcela.id} value={parcela.id}>
                  {parcela.parcela}
                </MenuItem>
              ))}
              {!parcelas.length && <MenuItem disabled>Nenhuma parcela encontrada</MenuItem>}
            </Select>
          </FormControl>
        </LoaderComponent>
      </Grid>
    </Grid>
  )
}