export function formataValorReais(event) {
  const rawValue = event.target.value.replace(/[^\d]/g, '')
  const value = parseFloat(rawValue) / 100;

  if (isNaN(value) || value === 0) {
    return ''
  }

  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value)
}

export function exibeValor(valor) {
  if (isNaN(valor) || valor === null || valor === undefined) {
    return 'R$ 0,00'; // Retorna um valor padrão se o valor for inválido
  }

  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(valor);
}

export function reaisParaNumero(valorFormatado){
  if (!valorFormatado) return 0; // Retorna 0 se o valor for vazio

  // Remove o prefixo do R$ e separadores de milhar, substitui vírgula por ponto
  const valorNormalizado = valorFormatado
    .replace(/\s/g, '')       // Remove espaços
    .replace('R$', '')        // Remove o símbolo R$
    .replace(/\./g, '')       // Remove pontos (separadores de milhar)
    .replace(',', '.');       // Substitui vírgula por ponto

  const valorNumerico = parseFloat(valorNormalizado);
  return isNaN(valorNumerico) ? 0 : valorNumerico; // Retorna 0 caso a conversão falhe 
}

// Remove objetos com atributos com valores repetidos de uma lista
export function removerDuplicados(lista, atributo) {
  const valoresUnicos = new Set()
  return lista.filter((obj) => {
    const valor = obj[atributo]
    if (!valoresUnicos.has(valor)) {
      valoresUnicos.add(valor)
      return true // Mantém o objeto
    }
    return false // Ignora o objeto duplicado
  })
}