import { AuthRoutes } from "./auth";
import { DashboardRoutes } from "./dashboard";
import { createBrowserRouter } from "react-router-dom";
import { publicMiddleware, authMiddleware } from "../app/middlewares";
import { MainLayout } from "../app/layouts";
import { RecursoRoutes } from "./recurso";
import { RoutesPath } from "app/shared/constants";
import { MacroAcaoRoutes } from "./macro-acao";

export const Routes = [
  {
    path: "/",
    loader: publicMiddleware,
    element: <></>,
  },
  {
    path: "/auth",
    loader: publicMiddleware,
    children: AuthRoutes,
  },
  {
    path: RoutesPath.DASHBOARD.path,
    element: <MainLayout />,
    loader: authMiddleware,
    children: DashboardRoutes,
  },
  {
    path: RoutesPath.RECURSO.path,
    element: <MainLayout />,
    loader: authMiddleware,
    children: RecursoRoutes
  },
  {
    path: RoutesPath.MACRO_ACAO.path,
    element: <MainLayout />,
    loader: authMiddleware,
    children: MacroAcaoRoutes
  }
];

export default createBrowserRouter(Routes);
