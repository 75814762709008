import { getUser, logout } from "app/services/auth/service";
import {
  AppKey,
  DOMAIN,
  DOMAIN_PAAR,
  TokenApi,
  TokenApp,
} from "app/shared/constants";
import axios from "axios";

const useHttp = (isMobiReq = false, responseType) => {
  const api = axios.create({
    baseURL: isMobiReq ? DOMAIN : DOMAIN_PAAR,
    responseType
  });

  api.interceptors.request.use(async (config) => {
    config.headers["Appkey"] = AppKey;
    config.headers["TokenApi"] = TokenApi;
    config.headers["Content-type"] = "application/x-www-form-urlencoded";
    config.headers["TokenApp"] = TokenApp;

    const user = await getUser();
    if (user) {
      const userToken = user.id + ":" + user.token + ":" + AppKey;
      config.headers["TokenUser"] = userToken;
    }
    return config;
  });

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const errorStatus = error?.response?.status;
      const errorCode = error?.code;
      if (errorCode === "ERR_CANCELED") {
        return null;
      }
      if (
        errorStatus === 403 &&
        error?.response?.data?.message ===
          "Token expirado, efetue login novamente!"
      ) {
        return logout(true);
      }
      if (error.message === "Token expirado, efetue login novamente!") {
        return logout(true);
      }
      return Promise.reject(error);
    }
  );
  return api;
};

export default useHttp;
