import { useReducer, useRef } from "react";
import useHttp from "../useHttp";
import useUnmount from "../useUnmount";
import toastr from 'toastr'
import { useDispatch, useSelector } from "react-redux";
import { setCache } from "app/redux/reducers/cacheSlice";
//trata isMobiReq para varios url base direfentes 
const useService = (service, isMobiReq = false, onSuccess, onError, configs) => {
  const dispatch = useDispatch()
  const cachedData = useSelector(state => state.cache);

  //IMPORTANTE - só pode ser TRUE para requsições 'GET' (busca de dados)
  const shouldUseCache = configs?.cache || false
  const cacheKey = configs?.cacheKey
  const responseType = configs?.responseType || "json"

  const [responseData, updateResponse] = useReducer(
    (state, action) => {
      return {
        ...state,
        ...action,
      };
    },
    {
      data: null,
      loading: false,
      error: false,
    }
  );

  const api = useHttp(isMobiReq, responseType);
  const controller = useRef(new AbortController());
  const retryParams = useRef({});

  const cancel = () => {
    controller.current.abort();
    controller.current = new AbortController();
  };

  const retry = () => {
    const lastParams = retryParams.current;
    request(lastParams);
  };

  const getFinalCacheKey = () => {
    const params = retryParams.current
    const finalCacheKey = `#${cacheKey}#-${JSON.stringify(params)}`
    return finalCacheKey
  }

  const getDeepCopy = (value) => {
    return JSON.parse(JSON.stringify(value))
  }

  const setResultOnCache = (data) => {
    if (!shouldUseCache || !cacheKey) return;
    const finalCacheKey = getFinalCacheKey()

    dispatch(setCache({ key: finalCacheKey, data: getDeepCopy(data) }));
  }

  const tryCache = () => {
    if (!shouldUseCache || !cacheKey) return;
    const finalCacheKey = getFinalCacheKey()
    const cachedReponse = cachedData?.[finalCacheKey]
    if (cachedReponse) {
      updateResponse({ data: getDeepCopy(cachedReponse) });
      onSuccess?.(cachedReponse);
      return true;
    }
    return false
  }

  const request = async (params = {}, ignoreCache = false) => {
    retryParams.current = params;

    if (!ignoreCache) {
      const successCache = tryCache(params)
      if (successCache) return;
    }

    try {
      updateResponse({ error: false, loading: true, data: null });
      const response = await service(api, params, controller.current);
      const data = response?.data;
      updateResponse({ error: false, loading: false, data: data });
      setResultOnCache(data)
      onSuccess?.(data);
    } catch (error) {
      const errorMessage = error?.response?.data?.message
      if (errorMessage) {
        toastr.error(errorMessage)
      }
      updateResponse({ error: true, loading: true });
      onError?.()
    } finally {
      updateResponse({ loading: false });
    }
  };

  useUnmount(() => {
    cancel();
  });

  return {
    request,
    cancel,
    response: responseData,
    retry,
  };
};

export default useService;
