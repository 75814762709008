import { GenericModal } from "app/shared/components";
import { Filtro } from "./Filtro";
import { Alert} from "@mui/material";
import { useState } from "react";
import toastr from 'toastr'
import { useService } from "app/shared/hooks";
import { exportaMacroAcaoEscola } from "app/services/macro-acoes-escola/post";

export function ModalExportacao({ open, close, successAction, dados}){
  const [dadosFiltro, setDadosFiltro] = useState({macroAcao: null, tipoPlano: null, plano: ''})

  const onSucess = (result) => {
    successAction(dados, result)
    setDadosFiltro({macroAcao: null, tipoPlano: null, plano: ''})
    close()
    toastr.success('Dados exportados com sucesso!')
  }

  const onError = () => {
    toastr.error('Ocorreu um problema ao realizar esta ação')
  }

  const { request, response: { loading } } = useService(exportaMacroAcaoEscola, false , onSucess, onError)
  
  function handleSubmit(){
    request({
      macro_acao_id_base: dados[0].macroAcao_id,
      parcela_id_base: dados[0].parcela_id,
      macro_acao_id_destino: dadosFiltro.macroAcao.value,
      parcela_id_destino: dadosFiltro.parcela,   
    })
  }

  const mesmosParametros = dados[0]?.macroAcao_id === dadosFiltro.macroAcao?.value && dados[0]?.parcela_id === dadosFiltro.parcela

  return (
    <GenericModal
      open={open}
      onClose={close}
      title="Exportação de dados"
      isLoading={loading}
      isDisabledSave={ Boolean(!dadosFiltro.macroAcao || !dadosFiltro.tipoPlano || !dadosFiltro.parcela) || loading || mesmosParametros }
      onSave={handleSubmit}
      textButton="EXPORTAR"
    >
      <Alert severity="info" sx={{marginTop: 2, marginBottom: 2}}>
        Os valores serão clonados para o plano e parcela selecionados abaixo:
      </Alert>

      <Filtro set={setDadosFiltro} modal={true} />

    </GenericModal>
  )
}