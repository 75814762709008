import { Box, TextField } from '@mui/material';
import { salvaMacroAcao } from 'app/services/macro-acoes/post';
import { GenericModal } from 'app/shared/components';
import { USER_LOGADO } from 'app/shared/constants/StorageKeys';
import { useService } from 'app/shared/hooks';
import { useEffect, useRef, useState } from 'react';
import toastr from 'toastr'

export function ModalCadastro({ open, close, setInsertMacro, existeMacro}) { 
  const [btnEnabled, setBtnEnabled] = useState(false)
  const [nome, setNome] = useState('')
  const inputNomeRef = useRef(null)

  const onSucess = (result) => {
    setInsertMacro(result) // Aciona o useEffect do componente Filtro   
    setNome('')
    close()
    toastr.success('Macro Ação cadastrada com sucesso!')
  }

  const onError = () => {
    toastr.error('Ocorreu um problema, tente novamente mais tarde')
  }

  const { request, response: { loading } } = useService(salvaMacroAcao, false , onSucess, onError)

  function handleChangeNome(event){
    setNome(event.target.value)
  }

  function handleSubmit(){
    // Dispara useEffect do componente "Filtro" para verificar se macroAcao ja existe
    existeMacro.set({...existeMacro.get, macro: nome}) 
  }

  useEffect(() => {
    if(!existeMacro.get.existe){ // Se não existe, faz a request
      const nome1 = nome.trim()
      const user = JSON.parse(window.atob(localStorage.getItem(USER_LOGADO)))
      request({
        nome: nome1,
        empresa_id: user?.empresa?.id || user?.relacionado?.empresa_id
      })
      existeMacro.set({...existeMacro.get, macro: '', existe: true})
    } 
  }, [existeMacro.get.existe])

  useEffect(() => {
    if(existeMacro.get.msgError > 0){
      toastr.error('Esta macro ação já foi cadastrada!')
    }
  }, [existeMacro.get.msgError])

  useEffect(() => {
    if(nome.trim().length > 2){
      setBtnEnabled(true)
    } else {
      setBtnEnabled(false)      
    }
  }, [nome])

  useEffect(() => { // Foca o input assim que a modal abre
    let timeout = null
    if(open){
      timeout = setTimeout(() => inputNomeRef.current?.focus(), 10)
      setNome('')
    }
    return () => clearTimeout(timeout)
  }, [open])

  return (
    <div>
      <GenericModal     
        open={open}
        onClose={close}
        title="Nova Macro Ação"
        isDisabledSave={!btnEnabled || loading}
        isLoading={loading}
        onSave={handleSubmit}
      >
        <Box marginTop={3}>
          <TextField 
            inputRef={inputNomeRef}
            size='small' 
            fullWidth 
            label='Nome' 
            value={nome} 
            onChange={handleChangeNome} 
          />
        </Box>

      </GenericModal>
    </div>
  );
}  


