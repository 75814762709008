import {
  Box,
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Theme } from "app/shared/utils";
import { FiX } from "react-icons/fi";

const GenericModal = ({
  open,
  onClose,
  title,
  size = "md",
  children,
  onSave,
  isLoading,
  isDisabledSave = false,
  hasCloseIcon = true,
  preventCloseClickOutside = false,
  textButton = "SALVAR",
  colorButton = "info"
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = (event, reason) => {
    if (
      preventCloseClickOutside &&
      ["backdropClick", "escapeKeyDown"].includes(reason)
    ) {
      return;
    }
    onClose?.();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      fullScreen={isMobile}
      maxWidth={size}
      disableEscapeKeyDown
    >
      <Box p={2} paddingTop={isMobile ? 8 : 2}>
        <Box
          width="100%"
          position="relative"
          display="flex"
          flexDirection="row"
          justifyContent={title ? "space-between" : "flex-end"}
        >
          {title && (
            <Typography variant="h6" color={Theme.colors.blue.dark}>
              {title}
            </Typography>
          )}
          {hasCloseIcon && (
            <IconButton
              style={{
                right: 0,
                top: 0,
                position: "absolute",
              }}
              onClick={onClose}
            >
              <FiX color={Theme.colors.black} size={15} />
            </IconButton>
          )}
        </Box>
        {children}
        {!!onSave && (
          <Grid container mt={5} justifyContent="flex-end">
            <Divider style={{ marginBottom: 15, marginTop: 5 }} />
            {!isLoading && <Button
              variant="contained"
              style={{
                marginLeft: 5,
                backgroundColor: "#f5f5f5",
                color: "black",
              }}
              size="small"
              onClick={onClose}
            >
              CANCELAR
            </Button>}
            <LoadingButton
              style={{
                marginLeft: 5,
              }}
              variant="contained"
              size="small"
              onClick={onSave}
              loading={isLoading}
              disabled={isDisabledSave}
              color={colorButton}
            >
              {textButton}
            </LoadingButton>
          </Grid>
        )}
      </Box>
    </Dialog>
  );
};

export default GenericModal;
